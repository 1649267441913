<template>
  <g>
    <svg:style>
      .cls-2,
      .cls-3,
      .cls-4 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }
      .cls-2{
        fill: url(#inox-gradient);
      }
      .cls-3{
        fill: url(#inox-gradient-2);
      }
      .cls-4{
        fill: url(#glass-pattern);
      }
    </svg:style>

    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth1 + 673.3"
                    :y1="doorTopHeight1 + 2380.26"
                    :x2="doorLeftWidth1 + 673.3"
                    :y2="doorTopHeight1 + 2380.26"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>

    <linearGradient id="inox-gradient-2"
                    :x1="doorLeftWidth1 + 691.06"
                    :y1="doorTopHeight1 + 2572.37"
                    :x2="doorLeftWidth1 + 691.06"
                    :y2="doorTopHeight1 + 2378.94"
                    xlink:href="#inox-gradient" />
    <g id="R05">
      <path id="inox"
            v-if="showInox"
            class="cls-2"
            :d="`M ${doorLeftWidth1 + 673.3} , ${doorTopHeight1 + 2380.26}`"
            transform="translate(-620.71 -2324.37)" />

      <path id="inox-2"
            v-if="showInox"
            data-name="inox"
            class="cls-3"
            :d="`
              M ${doorLeftWidth1 + 675.85} , ${doorTopHeight1 + 2571.95}
              c 21.31 -29 , 33.72 -60.79 , 32.94 -96.27 -0.39 -36.39 -10.69 -67.69 -32.8 -96.1 -0.84 -1 -2.68 -.88 -2.68 .69
              v 190.81
              A 1.51 , 1.51 , 0 , 0 , 0 , ${doorLeftWidth1 + 675.85} , ${doorTopHeight1 + 2571.95} Z
            `"
            transform="translate(-620.71 -2324.37)" />

      <path id="glass"
            class="cls-4"
            :d="`
              M ${doorLeftWidth1 + 678.27} , ${doorTopHeight1 + 2485.25}
              v -89.16
              a 1.4 , 1.4 , 0 , 0 , 1 , 2.63 -.74
              c 15.4 , 22.51 , 22.68 , 48.81 , 23.51 , 78.13 , 0.42 , 30.06 -7.13 , 57.66 -23.51 , 82.52 -0.62 .93 -2.63 , 0.79 -2.63 -.75
              v -70
            `"
            transform="translate(-620.71 -2324.37)" />
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
